import scooterCover from "../Images/Home mweb scooter cover.png";
import bikeHelmet from "../Images/bike helmet.png";
import bikeGloves from "../Images/bike gloves.jpg";
import {getEstimatedDate} from "../helper/Utils";

export const getAccessoriesList = () => {
    return [
        {
            id: 0,
            name: 'Bike Cover',
            price: '$999.00',
            date: `Ships by ${getEstimatedDate()}`,
            url: scooterCover,
            isAdded: false
        },
        {
            id: 1,
            name: 'Bike Helmet',
            price: '$1499.00',
            date: `Ships by ${getEstimatedDate()}`,
            url: bikeHelmet,
            isAdded: false
        },
        {
            id: 2,
            name: 'Bike Gloves',
            price: '$499.00',
            date: `Ships by ${getEstimatedDate()}`,
            url: bikeGloves,
            isAdded: false
        },
    ];
}