import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isBusiness: false,
    name: '',
    vip: '',
}

const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState,
    reducers: {
        setIsBusiness: (state, action) => {
            state.isBusiness = action.payload;
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setVIP: (state, action) => {
            state.vip = action.payload;
        },
        resetUserDetails: () => {
            return initialState;
        }
    },
});

export default userDetailsSlice.reducer;
export const {setIsBusiness, setName, setVIP, resetUserDetails} = userDetailsSlice.actions;