/** @jsxImportSource @emotion/react */
import React from 'react'
import { ClassNames, css ,jsx } from '@emotion/react'

const Slide = ({ content }) => (
  <div
    css={css`
      height: 100vh;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background: rgba(0, 0, 0, 0) !important;
      backdrop-filter: blur(0px) !important;
    `}
  >
    <img css={css`height:100%`}src={content} />
  </div>
)

export default Slide