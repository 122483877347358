import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import '../App.css'
import Logout from '../Login/Logout';
import MyOrders from './MyOrders';
import OrderBike from './OrderBike';
import TestRide from './TestRide';
import Location from './Location';
import {updateBySegments} from "../helper/Utils";
import {useDispatch, useSelector} from "react-redux";
import {gigyaOrgRegisterScreen} from "../helper/Constants.ts";
import {setIsBusiness, setName} from "../Features/UserDetails/userDetailsSlice";


const LandingPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { state } = location;
    const [isOrderPageVisible, setOrderPageVisible] = useState(false);
    const [isMyOrderPageVisible, setMyOrderPageVisible] = useState(false);
    const [isLogoutPageVisible, setIsLogoutPageVisible] = useState(false);
    const [isTestDrivePageVisible, setIsTestDrivePageVisible] = useState(false);
    const [isLocation, setIsLocation] = useState(false);
    const [orderId, setOrderId] = useState('');
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const dispatch = useDispatch();
    const fullName = useSelector(state => state.userDetails.name);
    const vipStatus = useSelector(state => state.userDetails.vip);

    const gigya = window.gigya;

    const handleOrderId = (orderId) => {
        setOrderId(orderId)
    }
    const handleMyOrder = () => {
        setOrderPageVisible(false)
        setMyOrderPageVisible(true)
        setIsLogoutPageVisible(false)
    }
    const handleClose = () => {
        setOrderPageVisible(false)
        setMyOrderPageVisible(false)
        setIsLogoutPageVisible(false)
        setIsTestDrivePageVisible(false)
        setIsLocation(false)
    }
    const handleTestDrive = () => {
        setOrderPageVisible(false)
        setMyOrderPageVisible(false)
        setIsLogoutPageVisible(false)
        setIsTestDrivePageVisible(true)

    }
    const handleOrder = async () => {
        await updateBySegments(dispatch, setShouldNavigate);
    }

    useEffect(() => {
        if (shouldNavigate) {
            setOrderPageVisible(true)
            setMyOrderPageVisible(false)
            setIsLogoutPageVisible(false)
            setShouldNavigate(false); // reset for future navigation
        }
    }, [shouldNavigate]);

    const handleLogout = () => {
        setIsLogoutPageVisible(!isLogoutPageVisible)
    }
    const handleLocation = () => {
        setIsLocation(true)
        setIsTestDrivePageVisible(false)
    }

    const navigateToHomeScreen = () => {
        navigate("/");
    }

    const getAccountInfoResponse  = (e) => {
        if ( e.errorCode == 0 ) {
            // var name = e.data.organizationName !== undefined ? e.data.organizationName : e.profile.firstName + ' ' + e.profile.lastName;
            let name;
            if (e.data.organizationName !== undefined) {
                name = e.data.organizationName;
                dispatch(setIsBusiness(true));
            }
            else {
                name = e.profile.firstName + ' ' + e.profile.lastName;
                dispatch(setIsBusiness(false));
            }
            dispatch(setName(name));
            window.fullName = name;
            window.email = e.profile.email;
        }
    }

    gigya.accounts.getAccountInfo({ callback: getAccountInfoResponse });
    return (
        <div className="App">
            <main>
                <nav className='Navbar Header_container Header_scrollColor'
                    style={{ marginTop: 0 }}>
                    <div className="Header_itemContainer">
                        <div>
                            <div className="Button_btn_wrapper primary ">
                                <button className="Button_btn_header" onClick={navigateToHomeScreen} style={{ fontWeight: '800'}}>SAP Motors
                                </button>
                            </div>
                            <div></div>
                            <div className="Button_btn_wrapper primary">
                                <button className="Button_btn_header" onClick={handleOrder}>Motorcycles</button>
                            </div>
                            <div className="Button_btn_wrapper primary">
                                <button className="Button_btn_header">Accessories</button>
                            </div>
                            <div className="Button_btn_wrapper primary">
                                <button className="Button_btn_header">Services</button>
                            </div>

                        </div>
                        <div>
                            {orderId || state[2] ?
                            <>
                                <div className="Button_btn_wrapper primary ">
                                    <button className="Button_btn_header" onClick={handleTestDrive}>Test Drive</button>
                                </div>
                                <div className="Button_btn_wrapper primary ">
                                    <button className="Button_btn_header" onClick={handleMyOrder}>My Orders</button>
                                </div>
                            </>: null}
                            <div className="Button_btn_wrapper primary Header_navbar_button Header_special_green_btn tooltip">
                                <button className="each-colour-active-np" style={{ height: '48px' }} onClick={handleLogout}>{window.fullName ? window.fullName.charAt(0) : 'X'}
                                    <div class="tooltiptext">{window.fullName}</div></button>
                            </div>
                        </div></div>
                </nav>
                <div className='header' style={isMyOrderPageVisible || isTestDrivePageVisible||isLocation ? { display: 'flex', justifyContent: 'center' } : null}>
                    {isOrderPageVisible ? <OrderBike state={state} myOrdersCallBack={handleOrderId} /> : null}
                    {!isMyOrderPageVisible && !isOrderPageVisible && !isTestDrivePageVisible && !isLocation ?
                        <div className='landing-page-card'>
                            <h1 style={{color:'#BDBFB7'}}>Welcome {fullName}</h1>
                            {vipStatus ? <h2 style={{color:'#BDBFB7'}}>Thank you for being a
                                    <span style={{color: '#2CAD3D'}}> {vipStatus} VIP </span>
                                    member with us!<br/>You will find a special discount just for you!</h2> :
                            <h2 style={{color:'#BDBFB7'}}>Discover exclusive benefits by becoming a
                                <span style={{color: '#2CAD3D'}}> VIP </span>
                                member! Make your first order now!</h2> }
                        </div>
                        : null}
                    {isMyOrderPageVisible ? <MyOrders orderId={orderId || state[2]} vehicleID={state[3]} closeMyOrder={handleClose} /> : null
                    }
                    {isTestDrivePageVisible ? <TestRide openLocation={handleLocation} closeTestRide={handleClose} /> : null}
                    {isLocation ? <Location closeTestRide={handleClose} /> : null}
                    {isLogoutPageVisible ? <Logout /> : null}
                </div>
            </main>
        </div>
    )
}

export default LandingPage;