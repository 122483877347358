import blueE1Image from "../Images/E1/blueImage4.png";
import greenE1Image from "../Images/E1/greenImage4.png";
import orangeE1Image from "../Images/E1/orangeImage4.png";
import purpleE1Image from "../Images/E1/purpleImage4.png";
import greyE1Image from "../Images/E1/greyImage4.png";
import asphaltE1Image from "../Images/E1/asphaltImage4.png";
import redE1Image from "../Images/E1/redImage4.png";
import blueE2Image from "../Images/E2/blueImage4.png";
import greenE2Image from "../Images/E2/greenImage4.png";
import orangeE2Image from "../Images/E2/orangeImage4.png";
import purpleE2Image from "../Images/E2/purpleImage4.png";
import greyE2Image from "../Images/E2/greyImage4.png";
import asphaltE2Image from "../Images/E2/asphaltImage4.png";
import redE2Image from "../Images/E2/redImage4.png";

export const Images = [
    {
        id: [{
            url: [blueE1Image]
        },
            {
                url: [greenE1Image]
            },
            {
                url: [orangeE1Image]
            },
            {
                url: [purpleE1Image]
            },
            {
                url: [greyE1Image]
            },
            {
                url: [asphaltE1Image]
            },
            {
                url: [redE1Image]
            }]
    },
    {
        id: [{
            url: [blueE2Image]
        },
            {
                url: [greenE2Image]
            },
            {
                url: [orangeE2Image]
            },
            {
                url: [purpleE2Image]
            },
            {
                url: [greyE2Image]
            },
            {
                url: [asphaltE2Image]
            },
            {
                url: [redE2Image]
            }]
    }
]