import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    // isRecommendedOne: false,
    // isRecommendedTwo: false,
    activeButton: "Select Model"
}

const orderManagementSlice = createSlice({
    name: 'orderManagement',
    initialState,
    reducers: {
        setActiveButton: (state, action) => {
            state.activeButton = action.payload;
        }
    },
});

export default orderManagementSlice.reducer;
export const {setActiveButton} = orderManagementSlice.actions;