import Chip from "@mui/material/Chip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React from "react";

export const RecommendedLabelAccessory = ({ id, recommended }) => {
    if (recommended) {
        return (
            <Chip
                icon={<CheckCircleIcon style={{fontSize: '20px'}} />}
                label="Recommended!"
                color="primary"
                style={{
                    backgroundColor: 'transparent',
                    color: 'green',
                    fontWeight: 'bold',
                    fontSize: '0.8em'
                }}
            />
        );
    }
    else {
        return (
            <Chip
                // icon={<CheckCircleIcon />}
                label=""
                color="primary"
                style={{
                    backgroundColor: 'transparent',
                    color: 'green',
                    fontWeight: 'bold'
                }}
            />
        );
    }
    return null;
};