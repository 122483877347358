import {configureStore} from "@reduxjs/toolkit";
import recommendedReducer from './Features/RecommendedMark/recommendedSlice'
import orderManagementReducer from './Features/OrderManagement/orderManagementSlice'
import userDetailsReducer from './Features/UserDetails/userDetailsSlice'

const store = configureStore({
    reducer: {
        recommended: recommendedReducer,
        orderManagement: orderManagementReducer,
        userDetails: userDetailsReducer,
    }
});

export default store;