import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import tickIcon from '../Images/tick-icon.svg';
import tick from '../Images/tick.svg'
import Slider from '../Slider/Slider'
import bgImage from '../Images/new14-min-min.png'
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import LoadingSpinner from './LoadingSpinner';
import { manageVehicleUrl, create_SO_Data } from '../helper/Info.ts'
import {
    gigyaDefaultRegisterScreen,
    gigyaProgProfilingScreen,
    responseStatus, vipBronze, vipGold, vipSilver
} from "../helper/Constants.ts";
import logout from "../Login/Logout";
import {getUserFromCDC} from "../Services/apiService";

import {delay, getEstimatedDate, updateBySegments} from "../helper/Utils";
import {useOrderState} from "./useOrderState";
import {getAccessoriesList} from "../Data/accessoriesData";
import {models} from "../Data/modelsData";
import {Images} from "../Data/imagesData";
import {colorList} from "../Data/colorsData";
import {ColorList} from "./Components/ColorList";
import {RecommendedLabelAccessory} from "./Components/RecommendedLabelAccessory";
import {RecommendedLabel} from "./Components/RecommendedLabel";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "@mui/material";
import {ButtonList, buttons} from "./Components/ButtonList";
import './Components/ButtonList.css'
import {setActiveButton} from "../Features/OrderManagement/orderManagementSlice";

const OrderBike = ({ state, isMyOrdersOpen, myOrdersCallBack }) => {
    const gigya = window.gigya;

    const dispatch = useDispatch();

    const {index, setIndex, modelIndex, setModelIndex, modelIndexRef} = useOrderState();
    const [accessoriesList, setAccessories] = useState(getAccessoriesList());

    const [activeClasses, setActiveClasses] = useState([true, false, false, false]);
    const [activeModelClasses, setActiveModelClasses] = useState([true, false]);
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedWarranty, setIsCheckedWarranty] = useState(false);
    const isCheckedWarrantyRef = useRef(isCheckedWarranty);
    useEffect(() => {
        isCheckedWarrantyRef.current = isCheckedWarranty;
    }, [isCheckedWarranty]);

    const [isOrdered, setIsOrdered] = useState(false);
    const [isSuccessPopup, setIsSuccessPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const recommendedOne = useSelector(state => state.recommended.isRecommendedOne);
    const recommendedTwo = useSelector(state => state.recommended.isRecommendedTwo);
    const activeButton = useSelector(state => state.orderManagement.activeButton);
    const vipStatus = useSelector(state => state.userDetails.vip);

    const [orderId, setOrderId] = useState('');
    const [orderPriceBike, setOrderPriceBike] = useState(100000);
    const [orderPriceAccessories, setOrderPriceAccessories] = useState(0);
    const orderPriceBikeRef = useRef(orderPriceBike);
    const orderPriceAccessoriesRef = useRef(orderPriceAccessories);

    useEffect(() => {
        orderPriceBikeRef.current = orderPriceBike;
    }, [orderPriceBike]);

    useEffect(() => {
        orderPriceAccessoriesRef.current = orderPriceAccessories;
    }, [orderPriceAccessories]);

    const [accessoriesActiveClasses, setAccessoriesActiveClasses] = useState([false, false])
    const [accessList, setAccessList] = useState(accessoriesList);

    const priceStringToInt = (priceString) => {
        const withoutSymbol = priceString.replace('$', '');
        const withoutCommas = withoutSymbol.replace(/,/g, '');
        const floatPrice = parseFloat(withoutCommas);
        return Math.round(floatPrice);
    }

    const intToPriceString = (value) => {
        const options = {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        };

        const formatted = new Intl.NumberFormat('en-US', options).format(value);

        // Replace $ symbol with $
        return formatted;
        // return formatted.replace('$', '₹');
    }

    const calculateVIPDiscount = () => {
        let currentPrice = isCheckedWarranty ? (1000 + orderPriceBike + orderPriceAccessories) : (orderPriceBike + orderPriceAccessories);
        let vipDiscount = 0;
        if (vipStatus === vipGold) vipDiscount = 3000;
        else if (vipStatus === vipSilver) vipDiscount = 2000;
        else if (vipStatus === vipBronze) vipDiscount = 1000;

        return currentPrice - vipDiscount;
    }

    const getData = () => {
        const getSOData = create_SO_Data;
        getSOData.soldToParty = state[1];
        return getSOData;
    }

    const sendRequest = useCallback(async () => {
        // don't send again while we are sending
        if (isOrdered) return
        // update state
        setIsOrdered(true)
        // send the actual request    
        setIsLoading(true)
        // wait 2.5 seconds to simulate order, after that proceed with the logic.
        delay(2500).then(async () => {
            const user = await getUserFromCDC();

            if (user.tradeIn === undefined) {
                // request here for more information with the register completion screen.
                // gigya.accounts.setscreenset....
                gigya.accounts.showScreenSet({
                    screenSet: gigyaDefaultRegisterScreen,
                    onAfterSubmit: onAfterSubmit,
                    startScreen: gigyaProgProfilingScreen
                });
            }
            else {
                orderNotFirstTime(user);
            }
        });
        setIsOrdered(false)
    }, [isOrdered]);

    const reportOrder = (UID, profile, orderPrice, financing, trading) => {
        setIsSuccessPopup(true);
        setIsLoading(false);

        const randomSalesOrder = Math.floor(Math.random() * 1000000).toString();
        const boughtAccessory = accessList.some(item => item.isAdded);
        const bikeName = models[modelIndexRef.current].name;

        myOrdersCallBack(randomSalesOrder);
        setOrderId(randomSalesOrder);

        window.CDP.report('New Order v2', {
            "orderPrice": orderPrice,
            "financing": financing,
            "UID": UID,
            "bikeName": bikeName,
            "hasWarranty": isCheckedWarrantyRef.current,
            "orderId": randomSalesOrder,
            "profile": {
                "lastName": profile.lastName,
                "email": profile.email,
                "firstName": profile.firstName
            },
            "trade_in": trading
        }).then(res => {
            console.log(res);
            delay(3000).then(async () => {
                await updateBySegments(dispatch, () => {
                });
            });
        });

        if (!boughtAccessory) {
            window.CDP.report('Send Accessory Coupon',
                {
                    "UID": UID,
                    "email": profile.email
                }
            ).then(res => console.log(res));
        }
    }

    const onAfterSubmit = async (event) => {
        if (event.screen === gigyaProgProfilingScreen) {
            if (event.response.status === responseStatus.OK) {
                reportOrder(
                    event.response.UID,
                    {lastName: event.profile.lastName, email: event.profile.email, firstName: event.profile.firstName},
                    getTotalOrderPrice(),
                    event.data.financing,
                    event.data.trading);
            }
        }
    }

    const orderNotFirstTime = (user) => {
        reportOrder(
            user.UID,
            {lastName: user.lastName, email: user.email, firstName: user.firstName},
            getTotalOrderPrice(),
            user.financing,
            user.trading
        );
    }

    const getTotalOrderPrice = () => {
        let orderPrice = orderPriceBikeRef.current + orderPriceAccessoriesRef.current;
        orderPrice = isCheckedWarrantyRef.current ? orderPrice + 1000 : orderPrice;

        let vipDiscount = 0;
        if (vipStatus === vipGold) vipDiscount = 3000;
        else if (vipStatus === vipSilver) vipDiscount = 2000;
        else if (vipStatus === vipBronze) vipDiscount = 1000;

        return orderPrice - vipDiscount;
    }

    const handleAccessories = (index) => {
        const active = accessoriesActiveClasses.map(() => false);
        active[index] = !accessoriesActiveClasses[index]
        setAccessoriesActiveClasses(active);
        accessList[index].isAdded = !accessList[index].isAdded;
        setAccessList(accessList);

        // loop through the accessories, sum the amount of each isAdded
        const total = accessList.reduce((acc, accessory) => {
           return accessory.isAdded ? acc + priceStringToInt(accessory.price) : acc;
        }, 0);
        setOrderPriceAccessories(total);
    }
    const checkBoxClicked = () => {
        setIsChecked(!isChecked);
    }

    const checkBoxClickedWarranty = () => {
        setIsCheckedWarranty(!isCheckedWarranty);
    }

    const toggleClass = (index) => {
        const active = activeClasses.map(() => false);
        active[index] = !activeClasses[index]
        setActiveClasses(active);
        setIndex(index)
    };
    const toggleModelClass = (index) => {
        const active = activeModelClasses.map(() => false);
        active[index] = !activeModelClasses[index]
        setActiveModelClasses(active);
        setModelIndex(index)
        setOrderPriceBike(priceStringToInt(models[index].price));
    };

    const handleNextClick = () => {
        const currentIndex = buttons.indexOf(activeButton);

        if (currentIndex === buttons.length - 1) {
            sendRequest();
        } else {
            dispatch(setActiveButton(buttons[currentIndex + 1]));
        }
    };

    const isLastStep = activeButton === buttons[buttons.length - 1];

    return (
        <div className='header-order'>
            {isLoading ? <LoadingSpinner style={{ position: 'absolute', width: '100%' }} /> : null}

            <Slider slides={Images[modelIndex].id[index].url} model={models[modelIndex]}/>

            <div className='right-container'>
                <div className='top'>
                    {isSuccessPopup ?
                        <Stack sx={{ zIndex: '1000', position: 'absolute', marginTop: '74px', width: '98%' }} spacing={2}>
                            <Alert onClose={() => setIsSuccessPopup(false)} severity="success">{`Sales Order (${orderId}) is Created`}</Alert>
                        </Stack> : null}
                    <ButtonList></ButtonList>
                </div>
                <div className='middle'>
                    {activeButton === buttons[0] ?
                        <div>
                            <div className='estimated'>
                                <div
                                    className="estimated-dispatch-container estimated-dispatch-container-for-mobile">{`Expected delivery by ${getEstimatedDate()}`}</div>
                            </div>
                            <div className='model-box-container-np model-box-container-np-for-mobile'>
                             {
                                models.map(item => {
                                    return (
                                        <div className={`model-selection-box-np model-selection-box-np-for-mobile ${activeModelClasses[item.id] ? 'model-selection-box-border-np' : 'model-selection-box-np'} model-selection-box-border-np-for-mobile`} onClick={() => toggleModelClass(item.id)}>
                                            <RecommendedLabel id={item.id} recommended={item.id === 0 ? recommendedOne : recommendedTwo} />
                                            <div className="model-title-and-info">
                                                <div>
                                                    <span className="new-s1-lable new-s1-lable-for-mobile">New</span>
                                                    <div className="model-title-np model-title-np-mobile">{item.name}</div>
                                                </div>
                                                <div className='stock-text' style={{ fontWeight: 'bold', color: item.stockColor, fontSize: item.textSize }}>{item.stock}</div>
                                            </div>
                                            <div className="range-speed-np range-speed-np-for-mobile">
                                                <div className="range-np range-np-for-mobile">
                                                    <div className="name-np name-np-for-mobile">RANGE</div>
                                                    <div className="val-np val-np-for-mobile">{item.range} <span className="small-val-np">km</span></div>
                                                </div>
                                                <div className="speed-np range-np-for-mobile">
                                                    <div className="name-np name-np-for-mobile">TOP SPEED</div>
                                                    <div className="val-np val-np-for-mobile">{item.speed} <span className="small-val-np">km/h</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div> : null}
                    {activeButton === buttons[1] ?
                        <div>
                            <div className="cv-desktop-color-name-wrapper">
                                <div className="cv-desktop-color">color</div>
                                <div className="cv-desktop-color">•</div>
                                <div className="cv-desktop-color-name">{colorList[index].color}</div>
                            </div>
                            <ColorList colorList={colorList} activeClasses={activeClasses} toggleClass={toggleClass}></ColorList>
                        </div>: null}
                    {activeButton === buttons[2] ?
                        <div className='accessories'>
                            <div className="warranty-checkbox-wrapper">
                                <div className={`warranty-checkbox ${isCheckedWarranty ? 'warranty-checkbox-checked' : ''}`} onClick={checkBoxClickedWarranty}>{isCheckedWarranty ? <img src={tick} alt="tick" /> : null}</div>
                                <div className="warranty-text">Would you like to buy a one year warranty? <br/>
                                    <div className='warranty-price'>$1,000.00</div>
                                </div>
                            </div>
                        </div>
                    : null}
                    {activeButton === buttons[3] ?
                        <div className='accessories'>
                            <h2 className='explore-accessories-heading'>Explore Accessories</h2>{accessList.map(item => {
                            return (
                                <div className='accessory-card-wrapper'>
                                    <div className="accessory-card-details">
                                        <RecommendedLabelAccessory id={item.id} recommended={item.id === 0 ? recommendedOne : recommendedTwo} />
                                        <div className="accessory-card-details-inner">
                                            <div className="accessory-card-item-name">{item.name}</div>
                                            <div className="accessory-card-item-price">{item.price}</div>
                                            <div className="accessory-card-dispatch-details">{item.date}</div>
                                            <div className="accessory-card-cta-wrapper">
                                                <div className='accessory-card-action-cta'
                                                     onClick={() => handleAccessories(item.id)}>
                                                    {item.isAdded ? <img src={tickIcon} alt="" /> : null}{item.isAdded ? 'Added' : 'Add'}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accessory-card-view-n-cta">
                                        <div className="accessory-card-item-image">
                                            <img className="h-100" src={item.url} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        </div>
                    : null}
                </div>
                <div className='bottom'>
                    <div className='footer-mweb-container-container'>
                        <div className="footer-mweb-bottom">
                            <div className="new-price-breakup-wrapper">

                                {vipStatus ?
                                    <div className="npb-fromat-amount-vip">
                                        {isCheckedWarranty ? intToPriceString(1000 + orderPriceBike + orderPriceAccessories) : intToPriceString(orderPriceBike + orderPriceAccessories)}
                                        <img src="https://olaelectricvideos.blob.core.windows.net/olaelectric-videos/configs-static/assets/newPurchase/infoIcon.svg" />
                                        <div className="npb-new-price">
                                            {intToPriceString(calculateVIPDiscount())}
                                            <span className="vip-discount">VIP Discount</span>
                                        </div>
                                    </div> :
                                    <div className="npb-fromat-amount">
                                        {isCheckedWarranty ? intToPriceString(1000 + orderPriceBike + orderPriceAccessories) : intToPriceString(orderPriceBike + orderPriceAccessories)}
                                        <img src="https://olaelectricvideos.blob.core.windows.net/olaelectric-videos/configs-static/assets/newPurchase/infoIcon.svg" />
                                    </div>
                                }

                                <div className='npb-format'>Ex-Showroom Price</div>
                            </div>
                            <div className="stepWrapper">
                                <span className="nextStepText">{isLastStep ? "Order" : "Next step"}</span>
                                <button className="ovalButton" onClick={handleNextClick}>→</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default OrderBike;