import React, {useContext, useEffect, useState} from 'react';
import './App.css';
import { useNavigate } from "react-router-dom";
import {
    gigyaLoginScreen,
    gigyaRegisterScreen,
    responseStatus,
    gigyaDefaultRegisterScreen,
    gigyaRegisterCompletionScreen, recommendedOneSegments, recommendedTwoSegments, gigyaOrgRegisterScreen
} from './helper/Constants.ts';
import {updateBySegments, delay} from "./helper/Utils";
import {useDispatch} from "react-redux";
import {setIsBusiness} from "./Features/UserDetails/userDetailsSlice";

function Home() {
    const navigate = useNavigate();
    const gigya = window.gigya;

    const [shouldNavigate, setShouldNavigate] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [isLoadingLogin, setIsLoadingLogin] = useState(false);
    const [isLoadingRegister, setIsLoadingRegister] = useState(false);
    const dispatch = useDispatch();

    const onLoginRaas = async (e) => {
        // var name = e.data.organizationName !== undefined ? e.data.organizationName : e.profile.firstName + ' ' + e.profile.lastName;
        // e.data.organizationName !== undefined ? dispatch(setIsBusiness(true)) : dispatch(setIsBusiness(false));
        let name;
        if (e.data.organizationName !== undefined) {
            name = e.data.organizationName;
            dispatch(setIsBusiness(true));
        }
        else {
            name = e.profile.firstName + ' ' + e.profile.lastName;
            dispatch(setIsBusiness(false));
        }

        window.fullName = name;
        window.email = e.profile.email;

        await updateBySegments(dispatch, setShouldNavigate);
    }

    useEffect(() => {
        if (shouldNavigate) {
            navigate("/loggedIn", { state: ["dummy1", "dummy2", "dummy3", "dummy4"] });
            setShouldNavigate(false); // reset for future navigation
        }
    }, [shouldNavigate, navigate]);


    // what happens after the user logins or registers:
    const onAfterSubmit = async (event) => {

        if (event.response.status === responseStatus.OK) {

            if (event.screen === gigyaLoginScreen) {
                await onLoginRaas(event);
            }

            else if (event.screen === gigyaRegisterCompletionScreen) {
                const eventName = event.preferences.marketing.isConsentGranted ? 'On Register' : 'On Register No Marketing';

                window.CDP.report(eventName,
                    {
                        "desired_range": event.data.milesTravel,
                        "user_email": event.profile.email,
                        "order_focus": event.data.needs,
                        "UID": event.response.UID,
                        "residency_type": event.data.areaType
                    }
                ).then((res) => {
                    delay(1000).then(async (res2) => {
                        await onLoginRaas(event);
                    })
                });
            }

            else if (event.screen === gigyaOrgRegisterScreen) {
                const eventName = event.preferences.marketing.isConsentGranted ? 'On Register Group' : 'On Register Group No Marketing';
                window.CDP.report(eventName,
                    {
                        "email": event.profile.email,
                        "orgName": event.data.organizationName,
                        "role": event.data.shared.role
                    }
                ).then((res) => {
                    delay(1000).then(async (res2) => {
                        await onLoginRaas(event);
                    })
                });


            }
        }
    }


    const onLogoutRaas = () => {
        navigate("/");
    }

    gigya.accounts.addEventHandlers({
        onLogout: onLogoutRaas
    });

    const LoginFormClick = () => {
        setIsLoadingLogin(true);
        setIsLoadingRegister(false);
        setIsRegister(false);
    }

    const RegistrationFormClick = () =>{
        setIsLoadingRegister(true);
        setIsLoadingLogin(false);
        setIsLogin(false);
    }

    useEffect(() => {
        const config = {
            screenSet: gigyaDefaultRegisterScreen,
            onAfterSubmit: onAfterSubmit
        };

        if (isLoadingLogin) {
            gigya.accounts.showScreenSet({
                ...config,
                containerID: 'gigyaContainer',
                onAfterScreenLoad: () => {
                    setIsLoadingLogin(false);
                    setIsLogin(true);
                },
            });
        }

        if (isLoadingRegister) {
            gigya.accounts.showScreenSet({
                ...config,
                containerID: 'gigyaContainer',
                startScreen: gigyaRegisterScreen,
                onAfterScreenLoad: () => {
                    setIsLoadingRegister(false);
                    setIsRegister(true);
                },
            });
        }
    }, [isLoadingLogin, isLoadingRegister]);

    const getAccountInfoResponse  = async (e) => {

        if (e.errorCode === 0) {
            await onLoginRaas(e);
        }
    }

    gigya.accounts.getAccountInfo({ callback: getAccountInfoResponse });

    return (
        <div className="App">
            <main>
                <nav className='Navbar Header_container Header_scrollColor'
                    style={{ marginTop: 0 }}>
                    <div className="Header_itemContainer">
                        <div>
                            <div className="Button_btn_wrapper primary ">
                                <button className="Button_btn_header"  style={{ fontWeight: '800'}}>SAP Motors
                                </button>
                            </div>

                        </div>
                        <div>
                            <div className="Button_btn_wrapper primary ">
                                <button className="Button_btn_header" onClick={() => LoginFormClick()}>Login</button>
                            </div>
                            <div className="Button_btn_wrapper primary Header_navbar_button Header_special_green_btn">
                                <button className="Button_btn_header" onClick={() => RegistrationFormClick()}>Register</button>
                            </div>
                        </div></div>
                </nav>
                <div className="header">
                <div className='celebration-text'>
           <h1> Celebrations with SAP Vehicles</h1></div>
                    {isLogin || isRegister ?
                        <div id='gigyaContainer' className='gigyaContainerStyle'></div>
                        : isLoadingLogin || isLoadingRegister ?
                            <div style ={{visibility:'hidden'}} id='gigyaContainer'></div>
                            : null}
                </div>
            </main>
        </div>
    );
}


export default Home;